var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-6 ml-6 mr-6"},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('nodeUpdate.title'))+" ")]),_c('v-divider')],1),_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"5"}},[_c('the-nerve-table',{attrs:{"id":"iiotNodeUpdateListTable","columns":_vm.nodeUpdateColumns,"is-row-clickable":_vm.canAccess('UI_NODE_UPDATE:UPDATE'),"is-action-menu-enabled":false,"store-module":"node-update-list","custom-fetch-action":"node_update_list","custom-list-getter":"getNodeUpdateList","have-access":_vm.canAccess('UI_SUBNAV_NODE_UPDATE:VIEW'),"is-search-enabled":false,"is-filter-columns-enabled":false,"empty-state":_vm.$t('nodeUpdate.nodeUpdateList.emptyText')},on:{"row-clicked":(params) => _vm.showCompatibleNodes(params.item)}})],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[(_vm.isCompatibleNodes)?_c('div',{attrs:{"id":"iiotNodeUpdateListCompatibleNodes"}},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('nodeUpdate.compatibleNodesTitle')))])],1),_c('v-card',{staticClass:"compatibleNodesCard"},[_c('the-nerve-table',{staticClass:"ml-5 pr-5",class:{
                compatibleNodesTable: _vm.getCompatibleNodes.length !== 0 && _vm.canAccess('UI_NODE_UPDATE:UPDATE'),
                compatibleNodesTableEmpty:
                  _vm.getCompatibleNodes.length === 0 ||
                  (_vm.getCompatibleNodes.length !== 0 && !_vm.canAccess('UI_NODE_UPDATE:UPDATE')),
              },attrs:{"id":'iiotCompatibleNodesList',"params":_vm.params,"columns":_vm.compatibleNodesColumns,"is-action-menu-enabled":false,"store-module":"node-update-list","custom-fetch-action":"compatible_nodes","custom-list-getter":"getCompatibleDevices","custom-count-getter":"getTotalCompatibleDevices","is-search-enabled":true,"is-filter-columns-enabled":false,"empty-state":_vm.$t('nodeUpdate.compatibleNodesList.emptyText'),"is-check-box-column-without-select-all-enabled":true,"is-row-clickable":true,"check-item-action":"select_device","selected-items-getter":"getSelectedNodeDevices"},on:{"params-changed":_vm.updateParams},scopedSlots:_vm._u([{key:"additional-actions",fn:function(){return [_c('v-col',{staticClass:"mt-n1"},[_c('v-checkbox',{attrs:{"id":"iiotNodeUpdateListSelectAllCheckbox","disabled":_vm.getCompatibleNodes.length === 0 || !_vm.canAccess('UI_NODE_UPDATE:UPDATE'),"label":_vm.$t('nodeUpdate.selectAll')},on:{"click":_vm.selectRemoveNodes},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1)]},proxy:true}],null,false,2724585000)}),_c('div',{staticClass:"pb-5"},[_c('nerve-button',{staticClass:"ml-5",attrs:{"id":"iiotNodeUpdateListUpdateNodesButton","text":_vm.$t('nodeUpdate.updateButton'),"disabled":_vm.getCompatibleNodes.length === 0 ||
                  !_vm.canAccess('UI_NODE_UPDATE:UPDATE') ||
                  _vm.getSelectedNodeDevices.length === 0,"type-of-btn":"action","size":"normal"},on:{"click-event":_vm.updateNodeToNewVersion}})],1)],1)],1):_vm._e()])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }